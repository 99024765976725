import React from "react"
import { Seo } from "../components/Seo"
import { Navbar } from "../components/Navbar"
import Footer from "../components/Footer"

function Layout({ children }) {
  return (
    <>
      <Seo />
      {/* Navbar */}
      <Navbar page="cs" />

      <main className="bg-light">{children}</main>
      <Footer color="bg-warning" text="black" />
    </>
  )
}

export default Layout
